import axios from "axios";

const WALLET_API = axios.create({
  baseURL: process.env.REACT_APP_WALLET_URL,
});

const CMS_API = axios.create({
  baseURL: process.env.REACT_APP_CMS_URL,
});

export { WALLET_API, CMS_API };